/* 
 * @Author: 曹俊杰 
 * @Date: 2022-04-28 17:04:23
 * @Module: pc自主上传
 */
 <template>
  <div class="PCupload">
    <hdq-section title="pc自主上传" more-text="" />
    <div class="header">
      <div class="l">排序</div>
      <div class="c">证据文件<b>*</b></div>
      <div class="c">证据类型<b>*</b></div>
      <div class="c">备注</div>
      <div class="r">删除</div>
    </div>
    <div class="content">
      <div class="item" v-for="(item,index) in list" :key="item.id">
        <div class="l">证据{{index+1}}</div>
        <div class="c">
          <div class="el-input__inner">
            <span v-if="item.name">{{item.name}}</span>
            <el-upload :before-upload="beforeUpload" :on-error="onError" v-else :show-file-list="false" :on-success="res=>onSuccess(res,item)" :headers="{Authorization:'Bearer ' + user.token}" action="/api/evidence/file" :limit="1">
              <el-button type="text" icon="el-icon-plus">点击添加本地文件</el-button>
            </el-upload>
          </div>
        </div>
        <div class="c">
          <el-select v-model="item.type" placeholder="请选择" style="width:100%;">
            <el-option v-for="item in typeList" :key="item.value" :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="c">
          <el-input placeholder="" v-model="item.note"></el-input>
        </div>
        <div class="r">
          <span class="el-icon-delete" style="font-size:16px;" @click="()=>remove(item)"></span>
        </div>
      </div>
    </div>
    <div class="add" @click="add">
      <span class="el-icon-plus"></span>
      <span>添加证据</span>
    </div>
    <div style="padding:0 24px;margin-top:12px;">
      <el-button type="primary" style="width:110px;" @click="postEvidenceList">提交</el-button>
    </div>
  </div>
</template>
 <script>
let loading;
import { mapState } from "vuex";
export default {
  components: {},
  data () {
    return {
      list: [],
      typeList: [],
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  mounted () {
    this.getArchiveDocFilter();
    this.add()

  },
  methods: {
    async getArchiveDocFilter () {
      const { status, data } = await this.$api.getArchiveDocFilter()
      if (status === 200) {
        this.typeList = data.type
      }
    },
    add () {
      this.list.push({
        id: this.$utils.guid(),
        name: '',
        url: '',
        note: '',
        type: ''
      })
    },
    remove ({ id }) {
      const i = this.list.findIndex(res => res.id === id)
      if (i > -1) {
        this.list.splice(i, 1)
      }
    },
    // 上传前
    beforeUpload ({ size }) {
      const maxSize = 5 * 1024 * 1024 * 1024;
      if (size > (maxSize)) {
        this.$message.error(`上传文件不能超过${this.$utils.formatBytes(maxSize)}`);
        return false
      } else {
        loading = this.$loading({
          lock: true,
          text: '文件上传中，耐心等待',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)'
        })
        return true
      }
    },

    onError (err) {
      this.loadingClose()
    },
    onSuccess ({ status, info, data }, { id }) {
      if (status === 200) {
        const { url, name } = data
        this.$message({
          type: 'success',
          message: info
        })
        const i = this.list.findIndex(res => res.id === id)
        this.list[i].url = url;
        this.list[i].name = name;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
      this.loadingClose()
    },
    loadingClose () {
      loading && loading.close();
    },
    async postEvidenceList () {
      const { list } = this
      const { status, info } = await this.$api.postEvidenceList({ list })
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.list = []
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.PCupload {
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding-bottom: 20px;
  b {
    color: #e05353;
    font-weight: 400;
  }
  .header {
    height: 40px;
    background: #f7f8f9;
    border-radius: 4px;
    margin: 0 24px;
    display: flex;
    align-items: center;

    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 17px;
  }
  .content {
    font-size: 12px;
    margin: 0 24px;
    .item {
      font-size: 12px;
      display: flex;
      align-items: center;
      background-size: 5px 30%;
      background-repeat: no-repeat;
      box-shadow: 0px 4px 6px 0px #f5f9ff;
      border-radius: 4px;
      border: 1px solid #e3e3e3;
      margin-top: 12px;
      height: 100px;
    }
  }
  .l {
    width: 114px;
    padding-left: 24px;
  }
  .c {
    width: calc((100% - 114px - 64px - (20px * 2)) / 3);
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  .r {
    width: 64px;
    text-align: center;
    span:hover {
      color: #e05353;
    }
  }
  .add {
    margin: 0 24px;
    line-height: 64px;
    background: #f5f9ff;
    border-radius: 4px;
    border: 1px dashed #66a1ff;
    margin-top: 12px;

    font-size: 14px;
    font-weight: 400;
    color: #0062ff;
    text-align: center;

    cursor: pointer;
  }
}
</style>